import { ApiFetchOptions, useApiFetch } from '@/shared'

const baseUrl = 'salespoint/'

export type Salespoint = {
  id: number
  checkdoc_can_enabled: boolean
  credit_type: SalespointCreditType
  notification_mode: SalespointNotificationMode
  checkpassport_enabled: boolean
  deleted_at: string | null
  title: string
  address_country: string | null
  address_postcode: string | null
  address_region: string | null
  address_city: string | null
  address_street: string | null
  address_house: string | null
  address_flat: string | null
  status: string
  broker_client_id: number
  product_category: string
  alfabank_id: string
  alfabankpos_id: string | null
  ilbs_id: string | null
  vostbank_id: string | null
  otpbank_id: string
  otpbankesign_id: string
  otpbank_verification: string | null
  otpbank_type_app: string | null
  expobank_id: string | null
  mtsbank_id: string | null
  crediteurope_id: string | null
  mtsbank_type: string | null
  mtsbank_isEDS: string | null
  renaissancebank_id: number
  renbank_scan_auth_type: number
  sovcomhalva_id: number
  checkdoc_manual_check_enabled: boolean
  generalapp_banks: string[]
  insurance_mode: SalespointInsuranceMode
  insurance_company: string
  directions_ids: number[]
  staff_ids: number[]
  permissions: string[]
  segment: string
  providing_credit_tariffs_ids: number[]
  address_text: string
  // domrf_link: string
}
export type SalespointNotificationMode = 'on' | 'off' | 'def_on' | 'def_off'
export type SalespointInsuranceMode = 'none' | 'our' | 'bank'
export type SalespointCreditType = 'loan' | 'installment' | 'all' | 'all_partial' | 'partial_installment'

/** Получение ТТ по её ID */
export const useGetSalespoint = () => useApiFetch<{ _data: { id: number } }, Salespoint>(baseUrl + 'get', {}, 'json')

/** Получить список ТТ. Есть возможность фильтровать список */
export const useGetSalespointList = () => useApiFetch<ApiFetchOptions, Salespoint[]>(baseUrl + 'get_list', {}, 'json')
